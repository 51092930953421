import React from "react"
import { Helmet } from "react-helmet"
import "../../css/PageWrapper.css"

function PageWrapper({ children }) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Adrian Casanova l</title>
        <link rel="manifest" href="../../assets/site.webmanifest" />
      </Helmet>
      <div class="container">{children}</div>
    </React.Fragment>
  )
}

export default PageWrapper
